import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { Link } from 'gatsby';
import Container from '../components/layout/default/container';
import SEO from '../components/layout/seo';
import PrivateRoute from '../components/private-route';
import { isBrowser } from '../utils/helpers';
import ShareLinks from '../components/products/share-links';
import Loadable from '../components/data/loadable';
import { getApiRequest } from '../utils/request';

const ThankYouPage = () => {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);

  useEffect(() => {
    const init = async (id) => {
      if (id) {
        const result = await getApiRequest(`/product/${id}}`);
        if (result) {
          setProduct(result);
        }
      }
      setLoading(false);
    };

    const query = queryString.parse(isBrowser ? window.location.search : '');
    init(query.prod);
  }, []);



  return (
    <Container hideNav>
      <SEO title="Thank you for registering!" />
      <Loadable loading={loading}>
        <div className="max-w-5xl mx-auto py-16 sm:py-20 px-4 sm:px-6">
          <img src="https://api.funbowlingleagues.com/images/fbl-email-cover.jpg" className="w-full rounded-lg mb-20" alt="Thank you!" />
          <h1 className="text-center font-bold text-2xl sm:text-4xl mb-12">Thank You For Registering For A League At Your Participating Bowling Center!</h1>
          <p className="text-lg sm:text-xl text-center mb-4">What to do next?</p>
          <p className="text-lg sm:text-xl text-center mb-4">{`1)`} Share this with a few friends and family members, maybe they'll join in the fun with you!</p>
          <ShareLinks product={product} />
          <p className="text-lg sm:text-xl text-center mb-8 mt-4">{`2)`} Check your email in-box, we’ve sent you a welcome email, and an invitation to join a private Facebook Group, just for league members</p>
          <p className="text-lg sm:text-xl text-center mb-8">{`3)`} Get ready to roll some strikes and spares!</p>


          <Link
            to="/app/dashboard"
            className="mt-10 mx-auto max-w-md w-full bg-indigo-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >Take Me To My Fun Bowling League Account</Link>
        </div>
      </Loadable>
    </Container>
  );
};

const ThankYou = () => {
    return (
    <PrivateRoute component={ThankYouPage} />
  );
};

export default ThankYou;
